import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AddressService } from '@shared/services';
import * as streetActions from '../actions';
import { exhaustMap, of, switchMap } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';

@Injectable()
export class StreetEffects {

  getAllStreet$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(streetActions.getAllStreet),
      exhaustMap(({ filters }) => {
        return this._addressService.currentDistricts$.pipe(
          filter((ds) => !!ds.length),
          map((dist) => dist.map((d: any) => d.code)),
          switchMap((code: string[]) => {
            return this._addressService.getShortStreetListByParent({ ...filters, parentCode: code }).pipe(
              map(({ data, total }) => {
                return streetActions.getAllStreetSuccess({ allStreets: data, totalNumberElements: total });
              }),
              catchError((error) => of(streetActions.getAllStreetFailure({ errorMessage: error.message }))),
            );
          }),
        );
      }),
    );
  });

  getMoreStreet$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(streetActions.getMoreStreet),
      switchMap(({ filters }) => {
        return this._addressService.currentDistricts$.pipe(
          filter((dists) => !!dists.length),
          map((dist) => dist.map((d: any) => d.code)),
          switchMap((code: string[]) => {
            return this._addressService.getShortStreetListByParent({ ...filters, parentCode: code }).pipe(
              map(({ data, total }) => {
                return streetActions.getMoreStreetSuccess({ allStreets: data, totalNumberElements: total });
              }),
              catchError((error) => of(streetActions.getMoreStreetFailure({ errorMessage: error.message }))),
            );
          }),
        );
      }),
    );
  });

  constructor(
    private actions$: Actions,
    private _addressService: AddressService,
  ) {}
}
