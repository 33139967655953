import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

import { DeviceService } from '@helpers/device.service';
import { map, switchMap } from 'rxjs/operators';
import { GetCountRealPropertyParams, GetCountRealPropertyResp, GetRealPropertySellApplicationListParams, RealPropertySellApplicationResp } from '@modules/real-property-data/models';
import { VIEW_MANAGER_URI } from '../../../const';

import * as models from '../models';
import { Store } from '@ngrx/store';
import { AppState } from '@app/ngrx/app-state/app-state';
import { getRealPropertyData, getRealPropertyDataCount, updateRealPropertyItemSuccess } from '@app/ngrx/real-property-data/actions';
import { getRealPropertyCollection, getRealPropertyCountData, getRealPropertyCountFilter, getRealPropertyDataLoading } from '@app/ngrx/real-property-data/states/real-property-data-getters.state';
import { REAL_PROPERTY_FILTER } from '@app/real-property-list-filter/models';
import { UtilService } from '@helpers/util.service';
import { SettingsService } from '@app/settings.service';

@Injectable({
  providedIn: 'root',
})
export class RealPropertyService {
  realPropertyData$: Observable<any> = this.store.select(getRealPropertyCollection);
  realPropertyTotalCountFilter$: Observable<number> = this.store.select(getRealPropertyCountFilter);
  realPropertyTotalCountData$: Observable<number> = this.store.select(getRealPropertyCountData);
  realPropertyDataLoading$: Observable<boolean> = this.store.select(getRealPropertyDataLoading);

  realPropertyFilter: GetRealPropertySellApplicationListParams = REAL_PROPERTY_FILTER();
  private currentLanguageAsKey = this._settingsService.getCurrentLanguageAsKey();

  constructor(
    @Inject(VIEW_MANAGER_URI) private viewManagerURI: string,
    private http: HttpClient,
    private deviceService: DeviceService,
    private store: Store<AppState>,
    private _utilService: UtilService,
    private _settingsService: SettingsService,
  ) {}

  public getRealPropertyData(filter: GetRealPropertySellApplicationListParams) {
    this.store.dispatch(getRealPropertyData({ filter }));
  }

  updateRealProperty(item: any) {
    this.store.dispatch(updateRealPropertyItemSuccess({ item }));
  }

  public serializeRealPropertyData(data: any) {
    return data.map((item: any) => {
      return {
        id: item.applicationId,
        realPropertyId: item.realPropertyId,
        isFavorite: item.isFavorite,
        price: item.objectPrice,
        viewCount: item.viewCount,
        photoIdList: this.createPhotoArray(item.photoIdList),
        agent: {
          id: item.agent.userId,
          fullName: `${item.agent.surname} ${item.agent.name}`,
          photoUuid: item.agent.photoUuid,
          phone: item.agent.phone,
        },
        address: `
              г. ${item.address.city[this.currentLanguageAsKey]}, 
              ${item.address.district[this.currentLanguageAsKey]} район, 
              ул. ${item.address.street[this.currentLanguageAsKey]} ${item.address.building}`,
        area: item.totalArea,
        roominess: `${item.numberOfRooms} комнатная`,
        surface: `${item.floor || ''} ${item.numberOfFloors ? ' из ' + item.numberOfFloors : ''}`,
        residentialComplexName: `${item.residentialComplexName ? 'ЖК ' + item.residentialComplexName : ''}`,
      };
    });
  }

  createPhotoArray(photoIdList: string[]) {
    if (!photoIdList.length) {
      return [];
    }
    return photoIdList.slice(0, 5).map((image) => this._utilService.getFilePath() + image);
  }

  public getRealPropertyList(filter: GetRealPropertySellApplicationListParams): Observable<RealPropertySellApplicationResp> {
    return this.deviceService.getDeviceUUID().pipe(
      switchMap((uuid) =>
        this.http.post<RealPropertySellApplicationResp>(`${this.viewManagerURI}/open-api/realProperty/getRealPropertyForWebClientApplication`, {
          ...filter,
          deviceUuid: uuid,
        }),
      ),
    );
  }

  public getRealPropertyCountDispatch(filter: any) {
    this.realPropertyFilter = { ...this.realPropertyFilter, ...filter };
    if (!this.realPropertyFilter.addressCodes.length) {
      this.realPropertyFilter.addressCodes = ['001001'];
    }
    this.store.dispatch(getRealPropertyDataCount({ filter: this.realPropertyFilter }));
  }

  public getRealPropertyCount(filter: GetCountRealPropertyParams): Observable<GetCountRealPropertyResp> {
    return this.deviceService.getDeviceUUID().pipe(
      switchMap((uuid) => {
        return this.http.post<GetCountRealPropertyResp>(`${this.viewManagerURI}/open-api/realProperty/getCountRealProperty`, {
          ...filter,
          deviceUuid: uuid,
        });
      }),
    );
  }

  public getRealPropertyById(objectId: number): Observable<models.SellApplication> {
    return this.deviceService
      .getDeviceUUID()
      .pipe(switchMap((uuid) => this.http.get<models.SellApplicationByIdResp>(`${environment.apiDataManagerUrl}/open-api/applications-client/${objectId}/${uuid}`).pipe(map((res) => res.data))));
  }

  public getRealPropertyByIds(objectIds: number[]) {
    return this.deviceService.getDeviceUUID().pipe(
      switchMap((uuid) =>
        this.http
          .post<models.SellApplicationByIdsResp>(`${environment.apiDataManagerUrl}/open-api/applications-client/getApplicationsByIdsForClient`, {
            applicationIds: objectIds,
            deviceUuid: uuid,
          })
          .pipe(map((res) => res.data)),
      ),
    );
  }

  public getSameRealPropertyList(body: models.SameSellApplicationListFilter): Observable<models.SameSellApplicationListResp> {
    return this.http.post<models.SameSellApplicationListResp>(`${environment.apiViewManagerUrl}/open-api/sell-application/getSameApplicationList`, body);
  }

  public getLikesCountByRealPropertyId(realPropertyId: models.GetRealPropertyLikeCountByRealPropertyIdParams): Observable<models.RealPropertyLikeCount> {
    return this.http.get<models.RealPropertyLikeCount>(`${environment.apiDataManagerUrl}/open-api/likes/getLikesCountByRealPropertyId/${realPropertyId}`);
  }
}
