import { environment } from '@environments/environment';
import { IAddressCode, IAddressFields } from '../interfaces/real-property-list-filter.interface';

export const DEFAULT_ADDRESS_FIELDS: IAddressFields = {
  district: [],
  street: [],
};

export const DEFAULT_ADDRESS_CODES: IAddressCode = {
  district: environment.appDefaultSettings.countries.kazakhstan.code,
  street: environment.appDefaultSettings.countries.kazakhstan.code,
};
