import { createAction, props } from '@ngrx/store';
import * as models from '@app/real-property-list-filter/models';

const COMPLEX = 'COMPLEX';

export const getAllComplex = createAction(`[${COMPLEX}] Get All Complex`, props<{ filter: models.GetResidentialComplexListByAddressParams }>());
export const getAllComplexSuccess = createAction(`[${COMPLEX}] Get All Complex Success`, props<{ allComplexes: any, totalNumberElements: number }>());
export const getAllComplexFailure = createAction(`[${COMPLEX}] Get All Complex Failure`, props<{ errorMessage: string }>());

export const updateCurrentComplex = createAction(`[${COMPLEX}] Update Current Complex`, props<{ currentComplexIds: number[] }>());

export const getMoreComplex = createAction(`[${COMPLEX}] Get More Complex`, props<{ filter: models.GetResidentialComplexListByAddressParams }>());
export const getMoreComplexSuccess = createAction(`[${COMPLEX}] Get More Complex Success`, props<{ allComplexes: any, totalNumberElements: number }>());
export const getMoreComplexFailure = createAction(`[${COMPLEX}] Get More Complex Failure`, props<{ errorMessage: string }>());
