import { createAction, props } from '@ngrx/store';
import * as models from '@modules/feedback/agent-feedback.model';

const FEEDBACK = 'FEEDBACK';

export const getAllFeedback = createAction(`[${FEEDBACK}] Get All Feedback`, props<{ filter: models.GetAllApplicationFeedbackParams }>());
export const getAllFeedbackSuccess = createAction(`[${FEEDBACK}] Get All Feedback Success`, props<{ feedbacks: any, totalCount: number }>());
export const getAllFeedbackFailure = createAction(`[${FEEDBACK}] Get All Feedback Failure`, props<{ errorMessage: string }>());

export const getMoreFeedback = createAction(`[${FEEDBACK}] Get More Feedback`, props<{ filter: models.GetAllApplicationFeedbackParams }>());
export const getMoreFeedbackSuccess = createAction(`[${FEEDBACK}] Get More Feedback Success`, props<{ feedbacks: any, totalCount: number }>());
export const getMoreFeedbackFailure = createAction(`[${FEEDBACK}] Get More Feedback Failure`, props<{ errorMessage: string }>());
