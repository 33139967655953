import { IFiltersState } from '@app/ngrx/filters/states/filters.state';
import { createReducer, on } from '@ngrx/store';

import * as filtersActions from '../actions';

const filtersInitialState: IFiltersState = {
  proposalId: null,
  dealType: { value: 2, label: 'Покупка' },
  price: { from: null, to: null },
  constructYear: { from: null, to: null },
  roominess: [],
  area: { from: null, to: null },
  layout: null,
  filtersCount: 0,
};

export const filtersReducer = createReducer(
  filtersInitialState,
  on(filtersActions.updateFilter, (state, { filters }) => {
    return { ...state, ...filters };
  }),
  on(filtersActions.updateFilterCount, (state, { count }) => {
    return { ...state, filtersCount: count };
  }),
  on(filtersActions.clearFilters, (state) => {
    return { ...state, ...filtersInitialState, filtersCount: 2 };
  }),
);
