import { AppState } from '@app/ngrx/app-state/app-state';
import { createSelector } from '@ngrx/store';
import { adapter } from '@app/ngrx/real-property-data/reducers';

export const getRealPropertyState = (state: AppState) => state.realPropertyData;

export const getRealPropertyCollection = adapter.getSelectors(getRealPropertyState).selectAll;

export const getRealPropertyDataLoading = createSelector(getRealPropertyState, (state) => state.loading);

export const getRealPropertyCountFilter = createSelector(getRealPropertyState, (state) => state.totalCountFilter);

export const getRealPropertyCountData = createSelector(getRealPropertyState, (state) => state.totalCountData);
