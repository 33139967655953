import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { DictionaryService } from '@shared/services';

import * as dictActions from '../actions';
import { of, switchMap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class DictionaryEffects {

  getHouseCondition$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dictActions.getHouseCondition),
      switchMap(() => {
        return this._dictService.fetchDictValues('HouseCondition').pipe(
          map((conditions) => dictActions.getHouseConditionSuccess({ conditions })),
          catchError((error) => of(dictActions.getHouseConditionFailure({ errorMessage: error.message }))),
        );
      }),
    );
  });

  getObjectTypes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dictActions.getObjectType),
      switchMap(() => {
        return this._dictService.fetchDictValues('ObjectType').pipe(
          map((types) => {
            // debugger;
            return dictActions.getObjectTypeSuccess({ types })
          }),
          catchError((error) => of(dictActions.getObjectTypeFailure({ errorMessage: error.message }))),
        );
      }),
    );
  });

  constructor(
    private actions$: Actions,
    private _dictService: DictionaryService,
  ) {}
}
