import { RealPropertyService } from './real-property.service';
import { SignUpShowService } from './sign-up-show.service';
import { BookingService } from './booking.service';
import { FileService } from './file.service';

export const services = [RealPropertyService, SignUpShowService, BookingService, FileService];

export * from './real-property.service';
export * from './sign-up-show.service';
export * from './booking.service';
export * from './file.service';
