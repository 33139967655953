import { createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import * as streetActions from '../actions';
import { IStreetState } from '@app/ngrx/street/states/street.state';
import * as sharedModels from '@shared/models';

export const adapter = createEntityAdapter<sharedModels.AddressObject>();

const streetInitialState: IStreetState = adapter.getInitialState({
  loaded: false,
  loading: false,
  currentStreetIds: [],
  totalNumberElements: 0,
});

export const streetReducer = createReducer(
  streetInitialState,
  on(streetActions.getAllStreet, streetActions.getMoreStreet, (state) => ({ ...state, loading: true, loaded: false })),
  on(streetActions.getAllStreetSuccess, (state, { allStreets, totalNumberElements }) => {
    return adapter.setAll(allStreets, { ...state, loading: false, loaded: true, totalNumberElements });
  }),
  on(streetActions.getMoreStreetSuccess, (state, { allStreets, totalNumberElements }) => {
    return adapter.addMany(allStreets, { ...state, loading: false, loaded: true, totalNumberElements });
  }),
  on(streetActions.updateCurrentStreet, (state, { currentStreetIds }) => {
    return { ...state, currentStreetIds };
  }),
  on(streetActions.getAllStreetFailure, streetActions.getMoreStreetFailure, (state) => ({ ...state, loading: false, loaded: false })),
);
