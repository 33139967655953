import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ProfileService } from '@modules/real-property-search-settings/services';

import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as searchActions from '../actions';

@Injectable()
export class SavedSearchEffects {

  getSearch$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(searchActions.getSearch),
      switchMap(() => {
        return this.profileService.fetchSearchList().pipe(
          map((search) => {
            return searchActions.getSearchSuccess({ data: search });
          }),
          catchError((err) => of(searchActions.getSearchFailure({ errorMessage: err.message }))),
        );
      }),
    );
  });

  removeSearch$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(searchActions.removeSearch),
      switchMap(({ id }) => {
        return this.profileService.removeSearchSetting(id).pipe(
          map(() => searchActions.removeSearchSuccess({ id })),
          catchError((err) => of(searchActions.removeSearchFailure({ errorMessage: err.message }))),
        );
      }),
    );
  });

  constructor(
    private actions$: Actions,
    private profileService: ProfileService,
  ) {}
}