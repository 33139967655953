import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AddressService } from '@shared/services';

import { of, switchMap} from 'rxjs';
import { catchError, filter, map, take } from 'rxjs/operators';

import * as districtActions from '../actions';
import * as streetActions from '../../street/actions';

@Injectable()
export class DistrictEffects {
  getAllDistricts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(districtActions.getAllDistrict),
      switchMap(() => {
        return this._addressService.currentCity$.pipe(
          take(1),
          filter((city) => !!city),
          switchMap((city) => {
            return this._addressService.getDistrictList(city.code).pipe(
              map((districts) => {
                return districtActions.getAllDistrictSuccess({ allDistricts: districts });
              }),
              catchError((err) => of(districtActions.getAllDistrictFailure({ errorMessage: err.message })))
            );
          }),
        );
      }),
    );
  });

  updateDistrict$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(districtActions.updateCurrentDistrict),
      filter(({ currentDistrictIds }) => !!currentDistrictIds.length),
      map(() => {
        return streetActions.getAllStreet({ filters: { pageNumber: 0, pageSize: 10 } });
      }),
    );
  });

  constructor(
    private actions$: Actions,
    private _addressService: AddressService,
  ) {}
}
