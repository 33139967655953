import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { DeviceService } from '@helpers/device.service';
import * as models from '../models';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '@app/ngrx/app-state/app-state';
import {
  getSavedSearchCollection, getSavedSearchLoaded,
  getSavedSearchLoading
} from '@app/ngrx/saved-search/states/saved-search-getters.state';
import { getSearch, removeSearch } from '@app/ngrx/saved-search/actions';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  private readonly URL: string = `${environment.apiUserManagerUrl}/open-api/profile-config`;

  savedSearchCollection$: Observable<models.GetProfileConfigByDeviceUuidResp> = this.store.select(getSavedSearchCollection);
  savedSearchLoading$: Observable<boolean> = this.store.select(getSavedSearchLoading);
  savedSearchLoaded$: Observable<boolean> = this.store.select(getSavedSearchLoaded);

  constructor(
    private deviceService: DeviceService,
    private http: HttpClient,
    private store: Store<AppState>,
  ) {}

  getSearchList() {
    this.store.dispatch(getSearch());
  }

  public fetchSearchList(): Observable<models.GetProfileConfigByDeviceUuidResp> {
    return this.deviceService.getDeviceUUID().pipe(switchMap((uuid) => this.http.get<models.GetProfileConfigByDeviceUuidResp>(`${environment.apiUserManagerUrl}/open-api/profile-config/${uuid}`)));
  }

  removeSearch(id: number) {
    this.store.dispatch(removeSearch({ id }));
  }

  public removeSearchSetting(id: models.DeleteProfileConfigByIdAndDeviceUuidParams['id']): Observable<HttpResponse<models.DeleteProfileConfigByIdAndDeviceUuidResp>> {
    return this.deviceService.getDeviceUUID().pipe(switchMap((uuid) => this.http.get<models.DeleteProfileConfigByIdAndDeviceUuidResp>(`${this.URL}/delete/${id}/${uuid}`, { observe: 'response' })));
  }
}
