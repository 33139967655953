import * as gm from 'generated/api/gm.api';

type GetResidentialComplexListByAddress = gm.paths['/open-api/residential-complexes/getShortInfoList'];
export type GetResidentialComplexListByAddressParams = GetResidentialComplexListByAddress['post']['parameters']['body']['dto'];
export type GetResidentialComplexListByAddressResp = GetResidentialComplexListByAddress['post']['responses']['200']['schema'];

type GetResidentialComplex = gm.paths['/open-api/residential-complexes/getResidentialComplexLight/{id}'];
export type GetResidentialComplexParams = GetResidentialComplex['get']['parameters']['path']['id'];
export type GetResidentialComplexResp = GetResidentialComplex['get']['responses']['200']['schema'];
export type ResidentialComplex = GetResidentialComplexResp['data'];
