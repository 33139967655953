import { AppState } from '@app/ngrx/app-state/app-state';
import { adapter } from '@app/ngrx/saved-search/reducers';
import { createSelector } from '@ngrx/store';

export const getSavedSearchState = (state: AppState) => state.savedSearch;

export const getSavedSearchCollection = adapter.getSelectors(getSavedSearchState).selectAll;

export const getSavedSearchLoading = createSelector(getSavedSearchState, (state) => state.loading);

export const getSavedSearchLoaded = createSelector(getSavedSearchState, (state) => state.loaded);
