import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { of, switchMap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { RealPropertyMapService } from '@modules/real-property-details/services/real-property-map.service';
import * as realPropertyActions from '../actions';

@Injectable()
export class RealPropertyMapDataEffects {
  getMapData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(realPropertyActions.getRealPropertyMapData),
      switchMap(({ filter }) => {
        return this._realPropertyMapService.getApplications(filter).pipe(
          map((data) => {
            return { data: this._realPropertyMapService.serializeRealPropertyData(data) };
          }),
          map(({ data }) => {
            return realPropertyActions.getRealPropertyMapDataSuccess({ data: data });
          }),
          catchError((error) => of(realPropertyActions.getRealPropertyMapDataFailure({ errorMessage: error.message }))),
        );
      }),
    );
  });

  constructor(
    private actions$: Actions,
    private _realPropertyMapService: RealPropertyMapService,
  ) {}
}
