import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';

import { Observable } from 'rxjs';

import * as models from './agent-feedback.model';
import { Store } from '@ngrx/store';
import { AppState } from '@app/ngrx/app-state/app-state';
import { getAllFeedback, getMoreFeedback } from '@app/ngrx/feedback/actions';
import {
  getFeedbackCollection,
  getFeedbackLoading,
  getFeedbackTotal
} from '@app/ngrx/feedback/states/feedback-getters.state';

@Injectable({
  providedIn: 'root',
})
export class FeedbackService {

  feedbackCollection$: Observable<any> = this.store.select(getFeedbackCollection);
  feedbackLoading$: Observable<boolean> = this.store.select(getFeedbackLoading);
  feedbackTotalCount$: Observable<number> = this.store.select(getFeedbackTotal);

  constructor(private http: HttpClient, private store: Store<AppState>) {}

  getAllFeedback(filter: models.GetAllApplicationFeedbackParams) {
    this.store.dispatch(getAllFeedback({ filter }));
  }

  getMoreFeedback(filter: models.GetAllApplicationFeedbackParams) {
    this.store.dispatch(getMoreFeedback({ filter }));
  }

  public getAllFeedbackRequest(obj: models.GetAllApplicationFeedbackParams): Observable<models.AllApplicationFeedback> {
    return this.http.post<models.AllApplicationFeedback>(`${environment.apiDataManagerUrl}/open-api/application-feedback/getAll`, obj);
  }
}
