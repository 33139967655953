import { AppState } from '@app/ngrx/app-state/app-state';
import { adapter } from '@app/ngrx/city/reducers';
import { createSelector } from '@ngrx/store';

export const getCityState = (state: AppState) => state.city;

export const getAllCitiesCollections = adapter.getSelectors(getCityState).selectAll;

export const getAllCitiesEntity = adapter.getSelectors(getCityState).selectEntities;

export const getCurrentCityId = createSelector(getCityState, (state) => state.currentCityId);

export const getCityLoading = createSelector(getCityState, (state) => state.loading);

export const getCityLoaded = createSelector(getCityState, (state) => state.loaded);

export const getCurrentCity = createSelector(
  getAllCitiesEntity,
  getCurrentCityId,
  (entities, id) => entities[id]
);
