import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import * as models from '../../real-property-data-map/models/request-map.model';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@app/ngrx/app-state/app-state';
import { getRealPropertyMapCollection } from '@app/ngrx/real-property-data-map/states/real-property-data-map-getters.state';
import { getRealPropertyMapData } from '@app/ngrx/real-property-data-map/actions';

@Injectable({
  providedIn: 'root',
})
export class RealPropertyMapService {
  pointsOnMap$: Observable<any> = this.store.select(getRealPropertyMapCollection);
  constructor(
    private _http: HttpClient,
    private store: Store<AppState>,
  ) {}

  getApplications(mapFilters: models.RealPropertyMapFilterParams) {
    return this._http.post<models.RealPropertyMapFilterResp>(`${environment.apiViewManagerUrl}/open-api/map/location/applications`, mapFilters);
  }

  public getRealPropertyMapData(filter: models.RealPropertyMapFilterParams) {
    this.store.dispatch(getRealPropertyMapData({ filter }));
  }

  serializeRealPropertyData(data: any) {
    return data.map((item: any) => {
      return {
        type: 'Feature',
        id: String(item.applicationId),
        geometry: {
          coordinates: [item.longitude, item.latitude],
          type: 'Point',
        },
        properties: {
          price: item.objectPrice,
        },
      };
    });
  }
}
