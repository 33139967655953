import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';

import { DeviceService } from '@helpers/device.service';
import { switchMap } from 'rxjs/operators';

import * as models from '../models';

@Injectable({ providedIn: 'root' })
export class ProfileService {
  private readonly URL: string = `${environment.apiUserManagerUrl}/open-api/profile-config`;

  constructor(
    private deviceService: DeviceService,
    private http: HttpClient,
  ) {}

  public saveSearchSetting(body: models.CreateProfileConfigForDeviceUuidParams) {
    return this.deviceService.getDeviceUUID().pipe(switchMap((uuid) => this.http.post(`${this.URL}`, { ...body, deviceUuid: uuid }, { observe: 'response' })));
  }
}
