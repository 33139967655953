import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { of, switchMap } from 'rxjs';
import { ResidentialComplexService } from '@app/real-property-list-filter/services';
import { catchError, map } from 'rxjs/operators';

import * as complexActions from '../actions';

@Injectable()
export class ComplexEffects {

  getAllComplex$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(complexActions.getAllComplex),
      switchMap(({ filter }) => {
        return this._complexService.fetchResidentialComplexAsListByCityCodeAndDistrictCode(filter).pipe(
          map((complex) => {
            return complexActions.getAllComplexSuccess({ allComplexes: complex.data.data.data, totalNumberElements: complex.data.total });
          }),
          catchError((error) => of(complexActions.getAllComplexFailure({ errorMessage: error.message }))),
        );
      }),
    );
  });

  getMoreComplex$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(complexActions.getMoreComplex),
      switchMap(({ filter }) => {
        return this._complexService.fetchResidentialComplexAsListByCityCodeAndDistrictCode(filter).pipe(
          map((complex) => {
            return complexActions.getMoreComplexSuccess({ allComplexes: complex.data.data.data, totalNumberElements: complex.data.total });
          }),
          catchError((error) => of(complexActions.getMoreComplexFailure({ errorMessage: error.message }))),
        );
      }),
    );
  });

  constructor(
    private actions$: Actions,
    private _complexService: ResidentialComplexService,
  ) {}
}
