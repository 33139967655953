import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import * as filtersActions from '../actions';
import { switchMap } from 'rxjs';
import { updateCurrentCity } from '@app/ngrx/city/actions';
import { updateCurrentComplex } from '@app/ngrx/complex/actions';
import { updateCurrentHouseCondition } from '@app/ngrx/dictionary/actions';
import { updateCurrentDistrict } from '@app/ngrx/district/actions';
import { updateCurrentStreet } from '@app/ngrx/street/actions';
import { map } from 'rxjs/operators';
import { IAllFilters } from '@app/ngrx/filters/states/filters.state';

@Injectable()
export class FiltersEffects {

  private initialFilters: IAllFilters = {
    dealType: null,
    realtyType: null,
    priceFrom: 0,
    priceTo: 0,
    areaFrom: 0,
    areaTo: 0,
    proposalId: null,
    constructYearFrom: null,
    constructYearTo: null,
    city: null,
    complex: [],
    district: [],
    street: [],
    layout: null,
    condition: null,
    roominess: [],
  };

  private count: number = 0;

  clearFilters$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(filtersActions.clearFilters),
      switchMap(() => {
        this.initialFilters = {
          dealType: null,
          realtyType: null,
          priceFrom: 0,
          priceTo: 0,
          areaFrom: 0,
          areaTo: 0,
          proposalId: null,
          constructYearFrom: null,
          constructYearTo: null,
          city: null,
          complex: [],
          district: [],
          street: [],
          layout: null,
          condition: null,
          roominess: [],
        };
        this.count = 2;
        return [
          updateCurrentCity({ currentCityId: 1 }),
          updateCurrentComplex({ currentComplexIds: [] }),
          updateCurrentHouseCondition({ conditionIds: [] }),
          updateCurrentDistrict({ currentDistrictIds: [] }),
          updateCurrentStreet({ currentStreetIds: [] }),
        ];
      }),
    );
  });

  countFilters$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(filtersActions.countFilters),
      map(({ filters }) => {
        const filterArray = Object.keys(filters);
        filterArray.forEach((key) => {
          if (Array.isArray(filters[key])) {
            if (!filters[key].length && this.initialFilters[key].length) {
              this.count--;
            } else {
              if (!this.initialFilters[key].length && filters[key].length) {
                this.count++;
              }
            }
          } else {
            if (!filters[key] && typeof this.initialFilters[key] === 'object') {
              return;
            }
            if (!filters[key] && this.initialFilters[key]) {
              this.count--;
            } else {
              if (!this.initialFilters[key] && filters[key]) {
                this.count++;
              }
            }
          }
        });
        this.initialFilters = { ...this.initialFilters, ...filters };
        return filtersActions.updateFilterCount({ count: this.count });
      }),
    );
  });

  checkValueUpdate(prev: any, curr: any): boolean {
    const prevValue = JSON.stringify(prev);
    const currValue = JSON.stringify(curr);
    return prevValue != currValue;
  }

  constructor(
    private actions$: Actions,
  ) {}
}
