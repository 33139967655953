import { createEntityAdapter } from '@ngrx/entity';
import { ISavedSearchState } from '@app/ngrx/saved-search/states';
import { createReducer, on } from '@ngrx/store';

import * as savedSearchActions from '../actions';

export const adapter = createEntityAdapter();

const saverSearchInitialState: ISavedSearchState = adapter.getInitialState({
  loaded: false,
  loading: false,
});

export const savedSearchReducer = createReducer(
  saverSearchInitialState,
  on(savedSearchActions.getSearch, (state) => ({ ...state, loading: true, loaded: false })),
  on(savedSearchActions.getSearchSuccess, (state, { data }) => {
    return adapter.setAll(data, { ...state, loading: false, loaded: true });
  }),
  on(savedSearchActions.removeSearchSuccess, (state, { id }) => {
    return adapter.removeOne(id, { ...state });
  }),
);
