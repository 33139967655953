export class AgentInfo {
  id: number;
  name: string;
  surname: string;
  patronymic: string | null;
  photoUuid: string | null;
  achievement: string | null;
  phone: string;
  averageClientRating: number;

  constructor(data: AgentInfo) {
    this.id = data.id;
    this.name = data.name;
    this.surname = data.surname;
    this.patronymic = data.patronymic;
    this.achievement = data.achievement;
    this.phone = data.phone;
    this.averageClientRating = data.averageClientRating;
    this.photoUuid = data.photoUuid;
  }
}
