import { createReducer, on } from '@ngrx/store';
import { ICountryState } from '@app/ngrx/country/states/country.state';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';

import * as countryActions from '../actions';

export const adapter: EntityAdapter<any> = createEntityAdapter();

export const countryInitialState: ICountryState = adapter.getInitialState({
  loading: false,
  loaded: false,
  currentCountry: null,
});

export const countryReducer = createReducer(
  countryInitialState,
  on(countryActions.setCurrentCountry, (state) => ({ ...state, loading: true, loaded: false })),
  on(countryActions.setCurrentCountrySuccess, (state, { currentCountry }) => {
    return { ...state, loading: false, loaded: true, currentCountry };
  }),
);
