import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppState } from '@app/ngrx/app-state/app-state';
import { getAllComplex, getMoreComplex, updateCurrentComplex } from '@app/ngrx/complex/actions';
import { getComplexCollection, getComplexLoading, getCurrentComplex } from '@app/ngrx/complex/states/complex-getters';
import { environment } from '@environments/environment';
import { Store } from '@ngrx/store';
import * as selectModels from '@shared/modules/select/user-select.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as models from '../models';

@Injectable({
  providedIn: 'root',
})
export class ResidentialComplexService implements selectModels.ISelectService<number> {
  complexList$: Observable<any> = this.store.select(getComplexCollection);
  complexLoading$: Observable<boolean> = this.store.select(getComplexLoading);
  currentComplex$: Observable<any> = this.store.select(getCurrentComplex);

  constructor(
    private http: HttpClient,
    private store: Store<AppState>,
  ) {}

  getComplexList(filter: models.GetResidentialComplexListByAddressParams) {
    this.store.dispatch(getAllComplex({ filter }));
  }

  getMoreComplexes(filter: models.GetResidentialComplexListByAddressParams) {
    this.store.dispatch(getMoreComplex({ filter }));
  }

  updateCurrentComplex(currentComplexIds: number[]) {
    this.store.dispatch(updateCurrentComplex({ currentComplexIds }));
  }

  fetchResidentialComplexAsListByCityCodeAndDistrictCode(filter: models.GetResidentialComplexListByAddressParams): Observable<models.GetResidentialComplexListByAddressResp> {
    return this.http.post<models.GetResidentialComplexListByAddressResp>(`${environment.apiGisManagerUrl}/open-api/residential-complexes/getShortInfoList`, filter);
  }

  // models.GetResidentialComplexParams)
  fetchResidentialComplexById(id: any): Observable<models.ResidentialComplex> {
    return this.http.get<models.GetResidentialComplexResp>(`${environment.apiGisManagerUrl}/open-api/residential-complexes/getResidentialComplexLight/${id}`).pipe(map((res) => res.data));
  }

  fetchResidentialComplexByIdsList(ids: any): Observable<models.ResidentialComplex> {
    let params = new HttpParams();
    if (ids?.length > 0) {
      ids.forEach((id: number) => {
        params = params.append('ids', id);
      });
    } else {
      params = params.append('ids', null);
    }
    return this.http.post(`${environment.apiGisManagerUrl}/open-api/residential-complexes/getResidentialComplexLightList`, params).pipe(map((res: any) => res.data));
  }

  public fetchItemList(body: selectModels.FetchListRequestBody): Observable<selectModels.FetchListResponse<number>> {
    return this.http
      .post<models.GetResidentialComplexListByAddressResp>(`${environment.apiGisManagerUrl}/open-api/residential-complexes/getResidentialComplexByAddress`, {
        pageNumber: body.pageNumber,
        pageSize: body.pageSize,
        residentialComplexName: body.searchData,
      })
      .pipe(map((res) => ({ empty: res.data.data.empty, list: res.data.data.data.map((item) => ({ value: item.id, label: item.houseName, address: item.address?.nameRu || null })) })));
  }

  public fetchResidentialComplexByAddress(body: selectModels.FetchListRequestBody): any {
    return this.http.post<models.GetResidentialComplexListByAddressResp>(`${environment.apiGisManagerUrl}/open-api/residential-complexes/getAllPageable`, body).pipe(
      map((res: any) => {
        return {
          data: res.data.data,
          total: res.total,
        };
      }),
    );
  }
}
