import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SettingsService } from '@app/settings.service';
import { environment } from '@environments/environment';
import * as sharedModels from '@shared/models';
import * as selectModels from '@shared/modules/select/user-select.model';
import { Observable, pipe } from 'rxjs';
import { map } from 'rxjs/operators';

import { Store } from '@ngrx/store';
import { AppState } from '@app/ngrx/app-state/app-state';
import { getAllCitiesCollections, getCityLoaded, getCityLoading, getCurrentCity, getCurrentCityId } from '@app/ngrx/city/states';
import { getAllCity, updateCurrentCity } from '@app/ngrx/city/actions';
import { getCurrentCountry } from '@app/ngrx/country/states';
import { setCurrentCountrySuccess } from '@app/ngrx/country/actions';
import {
    getCurrentDistricts,
    getDistrictCollection, getDistrictLoaded,
    getDistrictLoading
} from '@app/ngrx/district/states/district-getters.state';
import { getAllDistrict, updateCurrentDistrict } from '@app/ngrx/district/actions';
import { getCurrentStreets, getStreetCollection, getStreetLoading } from '@app/ngrx/street/states/street-getters.state';
import { getAllStreet, getMoreStreet, updateCurrentStreet } from '@app/ngrx/street/actions';

@Injectable({
  providedIn: 'root',
})
export class AddressService implements selectModels.ISelectService<number | string> {
  private readonly url: string = `${environment.apiGisManagerUrl}/open-api/address`;

  public countryList$: Observable<sharedModels.AddressObject[]> = this.getAddressObjectList(sharedModels.AddressObjectTypeList.Country);
  currentCountry$: Observable<{ id: number, name: string, parentCodeCountry: string }> = this.store.select(getCurrentCountry);

  public cityList$: Observable<sharedModels.AddressObject[]> = this.store.select(getAllCitiesCollections);
  public currentCity$: Observable<sharedModels.AddressObject> = this.store.select(getCurrentCity);
  public cityLoading$: Observable<boolean> = this.store.select(getCityLoading);
  public cityLoaded$: Observable<boolean> = this.store.select(getCityLoaded);
  public cityCurrentId$: Observable<number> = this.store.select(getCurrentCityId);

  public districtList$: Observable<any> = this.store.select(getDistrictCollection);
  public currentDistricts$: Observable<sharedModels.AddressObject[]> = this.store.select(getCurrentDistricts);
  public districtLoading$: Observable<boolean> = this.store.select(getDistrictLoading);
  public districtLoaded$: Observable<boolean> = this.store.select(getDistrictLoaded);

  public streetList$: Observable<sharedModels.AddressObject[]> = this.store.select(getStreetCollection);
  public currentStreets$: Observable<sharedModels.AddressObject[]> = this.store.select(getCurrentStreets)
  public streetLoading$: Observable<boolean> = this.store.select(getStreetLoading);

  constructor(
    private http: HttpClient,
    private settingsService: SettingsService,
    private store: Store<AppState>,
  ) {}

  public getCitiesList(objectTypeId: sharedModels.AddressObjectTypeList[] = []) {
    this.store.dispatch(getAllCity({ objectTypeId }));
  }

  public updateCurrentCity(currentCityId: number) {
    this.store.dispatch(updateCurrentCity({ currentCityId }));
  }

  public getDistricts() {
    this.store.dispatch(getAllDistrict());
  }

  public updateCurrentDistricts(districtIds: number[]) {
    this.store.dispatch(updateCurrentDistrict({ currentDistrictIds: districtIds }));
  }

  public getStreetList(filters: { pageNumber: number, pageSize: number, text?: string }) {
    this.store.dispatch(getAllStreet({ filters }));
  }

  getMoreStreet(filters: { pageNumber: number, pageSize: number, text?: string }) {
    this.store.dispatch(getMoreStreet({ filters }));
  }

  updateCurrentStreets(streetIds: number[]) {
    this.store.dispatch(updateCurrentStreet({ currentStreetIds: streetIds }));
  }

  setCurrentCountry({ id, name, parentCodeCountry }: { id: number, name: string, parentCodeCountry: string }) {
    this.store.dispatch(setCurrentCountrySuccess({ currentCountry: { id, name, parentCodeCountry } }));
  }

  public getAddressObjectList(objectTypeId: sharedModels.AddressObjectTypeList): Observable<sharedModels.AddressObject[]> {
    const currentLanguageAsKey = this.settingsService.getCurrentLanguageAsKey();
    return this.http.get<sharedModels.GetChildAddressObjectListResp>(`${this.url}/getObjects?typeId=${objectTypeId}`).pipe(
      map((res) =>
        res.data.map(
          ({ addressObject: { addressObject: address } }) =>
            ({
              id: address.id,
              code: address.code,
              name: address.name[currentLanguageAsKey as keyof sharedModels.Localization],
            }) as sharedModels.AddressObject,
        ),
      ),
    );
  }

  public getDistrictList(parentCode: string) {
    const currentLanguageAsKey = this.settingsService.getCurrentLanguageAsKey();
    return this.http.get<sharedModels.GetChildAddressObjectListResp>(`${this.url}/getObjects?typeId=2&parentCode=${parentCode}`).pipe(
      map(({ data }: sharedModels.GetChildAddressObjectListResp) => {
        return data.map((item) => {
          const { code, name, id } = item?.addressObject?.addressObject;
          return { code, id, name: name[currentLanguageAsKey as keyof sharedModels.Localization] };
        });
      }),
    );
  }

  public getChildAddressObjectList(
    parentCode: sharedModels.GetChildAddressObjectListQueryParams['parentCode'] = null,
    objectTypeId: sharedModels.AddressObjectTypeList[] = [],
  ): Observable<sharedModels.AddressObject[]> {
    const currentLanguageAsKey = this.settingsService.getCurrentLanguageAsKey();
    return objectTypeId.length
      ? this.http
          .get<sharedModels.GetChildAddressObjectListResp>(`${this.url}/getObjects?parentCode=${parentCode}&${objectTypeId.map((id) => `typeId=${id}`).join('&')}`)
          .pipe(toAddressObject(currentLanguageAsKey))
      : this.http.get<sharedModels.GetChildAddressObjectListResp>(`${this.url}/getObjects?parentCode=${parentCode}`).pipe(toAddressObject(currentLanguageAsKey));
  }

  public fetchAddressObjectListByParentAddressObjectId(id: sharedModels.GetAddressObjectListByParentAddressObjectIdParams): Observable<sharedModels.GetAddressObjectListByParentAddressObjectIdResp> {
    return this.http.get(`${this.url}/getObjectListByParent/${id}`);
  }

  public fetchStreetListByParent(text: string, parentCode: string, pageInfo = { pageSize: 10, pageNumber: 0, sortBy: 'id', direction: 'ASC' }): Observable<sharedModels.AddressObject[]> {
    const currentLanguageAsKey = this.settingsService.getCurrentLanguageAsKey();
    return this.http
      .post<sharedModels.GetShortStreetListByParentResp>(
        `${this.url}/getShortStreetsByParent`,
        // TODO :sharedModels.GetShortStreetListByParentParams
        { text, parentCode, ...pageInfo } as any,
      )
      .pipe(
        map((res) =>
          res.data.data.map(
            (address) =>
              ({
                id: address.id,
                code: address.code,
                name: address.name[currentLanguageAsKey as keyof sharedModels.AddressObject['name']] as string,
              }) as sharedModels.AddressObject,
          ),
        ),
      );
  }
  //TODO: sharedModels.GetStreetByCodeParams
  public getStreetByCode(streetCode: any): Observable<sharedModels.GetStreetByCodeResp> {
    return this.http.get(`${this.url}/getStreetByCode/${streetCode}`);
  }

  public fetchItemList(body: selectModels.FetchListRequestBody): Observable<selectModels.FetchListResponse<number | string>> {
    const currentLanguageAsKey = this.settingsService.getCurrentLanguageAsKey();
    if ((body as any)?.type) {
      switch ((body as any).type) {
        case sharedModels.AddressType.Country:
          return this.getAddressObjectList(sharedModels.AddressObjectTypeList.Country).pipe(
            map((res) => ({
              empty: res.length === 0,
              list: res.map((address) => ({
                value: address.code,
                label: address.name,
              })),
            })),
          );
        case sharedModels.AddressType.AllCity:
          return this.getChildAddressObjectList((body as any).addressType, [
            sharedModels.AddressObjectTypeList.MainCity,
            sharedModels.AddressObjectTypeList.AreaCenterCity,
            sharedModels.AddressObjectTypeList.City,
          ]).pipe(
            map((res) => ({
              empty: res.length === 0,
              list: res.map((address) => ({
                value: address.id,
                label: address.name,
              })),
            })),
          );
        default:
          return this.http
            .post<sharedModels.GetShortStreetListByParentResp>(`${this.url}/getShortStreetsByParent`, {
              pageNumber: body.pageNumber,
              text: body.searchData,
              parentCode: (body as any).addressCodes,
            } as sharedModels.GetShortStreetListByParentParams)
            .pipe(
              map((res) => ({
                empty: res.data.empty,
                list: res.data.data.map((address) => ({
                  value: address.code,
                  label: address.name[currentLanguageAsKey as keyof sharedModels.AddressObject['name']] as string,
                })),
              })),
            );
      }
    } else {
      return this.http
        .post<sharedModels.GetShortStreetListByParentResp>(`${this.url}/getShortStreetsByParent`, {
          pageNumber: body.pageNumber,
          text: body.searchData,
          parentCode: (body as any).addressCodes,
        } as sharedModels.GetShortStreetListByParentParams)
        .pipe(
          map((res) => ({
            empty: res.data.empty,
            list: res.data.data.map((address) => ({
              value: address.code,
              label: address.name[currentLanguageAsKey as keyof sharedModels.AddressObject['name']] as string,
            })),
          })),
        );
    }
  }

  public getShortStreetListByParent(body: sharedModels.GetShortStreetListByParentParams): Observable<any> {
    const currentLanguageAsKey = this.settingsService.getCurrentLanguageAsKey();
    return this.http.post<sharedModels.GetShortStreetListByParentResp>(`${this.url}/getShortStreetsByParent`, body).pipe(
      map((res) => {
        return {
          data: res.data.data.map((address) =>
              ({ ...address, name: address.name[currentLanguageAsKey as keyof sharedModels.AddressObject['name']] as string })),
          total: res.total,
        };
      }),
    );
  }

  public restoreSelectedCity(addressCodes: string, cityList: sharedModels.AddressObject[]) {
    const city = SettingsService.city;
    const cityFromAddress = cityList.find((city) => addressCodes.startsWith(city.code));
    if (city) {
      return city?.id;
    } else {
      SettingsService.city = cityFromAddress;
      return environment.appDefaultSettings.cities.kazakhstan;
    }
  }
}

const toAddressObject = (currentLanguageAsKey: string) =>
  pipe(
    map((res: sharedModels.GetChildAddressObjectListResp) => {
      return res.data.map(
        ({ addressObject: { addressObject: address}}) =>
          ({
            id: address.id,
            code: address.code,
            name: (address.name[currentLanguageAsKey as keyof sharedModels.Localization] as string) ?? '',
            translateNames: address.name,
          }) as sharedModels.AddressObject,
      );
    }),
  );
