import { createReducer, on } from '@ngrx/store';
import { IDictionaryState } from '@app/ngrx/dictionary/states/dictionary.state';
import { createEntityAdapter } from '@ngrx/entity';

import * as dictActions from '../actions';

export const adapter = createEntityAdapter();

const dictionaryInitialState: IDictionaryState = adapter.getInitialState({
  loading: false,
  loaded: false,
  objectTypes: [],
  currentHouseConditionIds: [],
  currentObjectTypesId: null,
});

export const dictionaryReducer = createReducer(
  dictionaryInitialState,
  on(dictActions.getHouseCondition, (state) => ({ ...state, loading: true, loaded: false })),
  on(dictActions.getHouseConditionSuccess, (state, { conditions }) => {
    return adapter.setAll(conditions, { ...state, loading: false, loaded: true });
  }),
  on(dictActions.getObjectTypeSuccess, (state, { types }) => {
    return { ...state, objectTypes: types };
  }),
  on(dictActions.updateCurrentHouseCondition, (state, { conditionIds }) => {
    return { ...state, currentHouseConditionIds: conditionIds };
  }),
  on(dictActions.updateCurrentObjectType, (state, { objectTypeId }) => {
    return { ...state, currentObjectTypesId: objectTypeId };
  }),
  on(dictActions.getHouseConditionFailure, dictActions.getObjectTypeFailure, (state) => ({ ...state, loading: false, loaded: false }))
);
