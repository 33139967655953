<div class="container" [style.width]="width">
    <ng-select
        #userSelector
        [appendTo]="'body'"
        [loading]="loading"
        loadingText="Загрузка..."
        [formControl]="formControl"
        [items]="itemList"
        [bindValue]="bindValue"
        [bindLabel]="bindLabel"
        [placeholder]="placeholder"
        (scrollToEnd)="onNextPage()"
        [notFoundText]="noFoundText"
        [multiple]="multiple"
        (search)="onSearch($event)"
        (open)="onOpen()"
        [searchable]="searchable"
        [clearable]="clearable"
        (change)="onChange($event)"
        [disabled]="disabled"
        [classList]="'h' + minHeightClass">
        <ng-container *ngIf="withAddress">
            <ng-template ng-option-tmp let-item="item">
                <div class="residential-complex-list-item">
                    <div class="label">{{item.label}}</div>
                    <div class="address" [title]="item.address">
                        {{item.address}}
                    </div>
                </div>
            </ng-template>
        </ng-container>
    </ng-select>
</div>

