import * as vm from 'generated/api/vm.api';
import * as dm from 'generated/api/dm.api';

type SellApplicationById = dm.paths['/open-api/applications-client/{id}/{deviceUuid}'];
export type SellApplicationByIdResp = SellApplicationById['get']['responses']['200']['schema'];
export type SellApplication = SellApplicationByIdResp['data'];

type SellApplicationByIds = dm.paths['/open-api/applications-client/getApplicationsByIdsForClient'];
export type SellApplicationByIdsResp = SellApplicationByIds['post']['responses']['200']['schema'];
// export type SellApplications = SellApplicationByIds['data'];

export type InfrastructureObject = dm.definitions['DictionaryObjectResponseDto'];

type GetSameSellApplicationList = vm.paths['/open-api/sell-application/getSameApplicationList']['post'];
export type SameSellApplicationListFilter = GetSameSellApplicationList['parameters']['body']['dto'];
export type SameSellApplicationListResp = GetSameSellApplicationList['responses']['200']['schema'];
export type SameSellApplicationList = SameSellApplicationListResp['data']['data'];

type GetRealPropertyLikeCountByRealPropertyId = dm.paths['/open-api/likes/getLikesCountByRealPropertyId/{realPropertyId}'];
export type GetRealPropertyLikeCountByRealPropertyIdParams = GetRealPropertyLikeCountByRealPropertyId['get']['parameters']['path']['realPropertyId'];
export type RealPropertyLikeCount = GetRealPropertyLikeCountByRealPropertyId['get']['responses']['200']['schema'];
