import { createEntityAdapter } from '@ngrx/entity';
import { IDistrictsState } from '@app/ngrx/district/states';
import { createReducer, on } from '@ngrx/store';

import * as districtActions from '../actions';
import { AddressObject } from '@shared/models';

export const adapter = createEntityAdapter<AddressObject>();

const districtInitialState: IDistrictsState = adapter.getInitialState({
  loading: false,
  loaded: false,
  currentDistrictIds: [],
});

export const districtReducer = createReducer(
  districtInitialState,
  on(districtActions.getAllDistrict, (state) => ({ ...state, loading: true, loaded: false })),
  on(districtActions.getAllDistrictSuccess, (state, { allDistricts }) => {
    return adapter.setAll(allDistricts, { ...state, loading: false, loaded: true });
  }),
  on(districtActions.getAllDistrictFailure, (state) => ({ ...state, loading: false, loaded: false })),
  on(districtActions.updateCurrentDistrict, (state, { currentDistrictIds }) => {
    return { ...state, currentDistrictIds };
  }),
);
