import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, switchMap } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';
import { AddressService } from '@shared/services';

import * as cityActions from '../actions';
import * as districtActions from '../../district/actions';
import { updateCurrentDistrict } from '../../district/actions';

@Injectable()
export class CityEffects {
  getAllCities$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(cityActions.getAllCity),
      switchMap(({ objectTypeId }) => {
        return this._addressService.currentCountry$.pipe(
          filter((country) => !!country),
          switchMap((country: any) => {
            return this._addressService.getChildAddressObjectList(country.parentCodeCountry, objectTypeId).pipe(
              map((value) => {
                return cityActions.getAllCitySuccess({ allCities: value });
              }),
              catchError((error) => of(cityActions.getAllCityFailure({ errorMessage: error.message }))),
            );
          }),
        );
      }),
    );
  });

  updateCurrentCity$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(cityActions.updateCurrentCity),
      switchMap(() => {
        return [districtActions.getAllDistrict()];
      }),
    );
  });

  constructor(
    private actions$: Actions,
    private _addressService: AddressService,
  ) {}
}
