import { createAction, props } from '@ngrx/store';
import { IAllFilters, IFiltersState } from '@app/ngrx/filters/states/filters.state';

const FILTER = 'FILTER';

export const updateFilter = createAction(`[${FILTER}] Update Filters`, props<{ filters: IFiltersState }>());

export const countFilters = createAction(`[${FILTER}] Count Filters`, props<{ filters: IAllFilters }>());

export const updateFilterCount = createAction(`[${FILTER}] Increase Filter Count`, props<{ count: number }>());

export const clearFilters = createAction(`[${FILTER}] Clear Filters`);
