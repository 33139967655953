import { Injectable } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import * as models from '@app/real-property-list-filter/models';
import { SettingsService } from '@app/settings.service';
import * as sharedModels from '@shared/models';
import { Subject } from 'rxjs';
import { DEFAULT_ADDRESS_CODES, DEFAULT_ADDRESS_FIELDS } from '../constants/real-property-list-filter.constant';
import { IAddressCode, IAddressFields } from '../interfaces/real-property-list-filter.interface';

@Injectable({
  providedIn: 'root',
})
export class RealPropertyListFilterService {
  private _realPropertyFilter$: Subject<models.GetRealPropertySellApplicationListParams> = new Subject();
  public realPropertyFilter$ = this._realPropertyFilter$.asObservable();
  realSortProperty: { sortBy: string; direction: string } = { sortBy: null, direction: null };

  private _redirectTo$ = new Subject();
  public redirectTo$ = this._redirectTo$.asObservable();

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
  ) {}

  public setRealPropertyFilter(filter: models.GetRealPropertySellApplicationListParams): void {
    if (filter?.encumbrance === false) filter.encumbrance = null;
    if (filter?.exchange === false) filter.exchange = null;
    if (filter?.probabilityOfBidding === false) filter.probabilityOfBidding = null;
    this._realPropertyFilter$.next(filter);
  }

  public setSortFilter(sortBy: string, direction: string): void {
    this.realSortProperty = {
      sortBy,
      direction,
    };
  }
  public setRoute(filter: models.GetRealPropertySellApplicationListParams, redirectTo?: string): void {
    let params = {};
    filter.sortBy = this.realSortProperty.sortBy;
    filter.direction = this.realSortProperty.direction as any;

    if (filter) {
      params = this.stringifyFilterAsParams(filter);
    }

    let route = null;
    if (!redirectTo && SettingsService.country) {
      const code = sharedModels.CountryList[SettingsService.country?.code];
      if (code === this._route.snapshot.url) {
        route = this._route.snapshot.url;
      } else {
        route = code;
      }
    } else if (redirectTo) {
      route = redirectTo;
    } else {
      route = this._route.snapshot.url;
    }
    this._router
      .navigate([route], {
        queryParams: {
          ...params,
        },
        relativeTo: this._route,
      })
      .then();
  }

  public stringifyFilterAsParams(filter: models.GetRealPropertySellApplicationListParams): { [key: string]: any } {
    const params: { [key: string]: any } = {};

    if (filter.addressCodes) {
      params.addressCodes = filter.addressCodes;
    }

    if (filter.operationTypeId) {
      params.operationTypeId = filter.operationTypeId;
    }

    if (filter.objectTypeId) {
      params.objectTypeId = filter.objectTypeId;
    }

    if (filter.numberOfRooms?.length) {
      params.numberOfRooms = filter.numberOfRooms;
    }

    if (filter.atelier !== null) {
      params.atelier = filter.atelier;
    }

    if (filter.encumbrance) {
      params.encumbrance = filter.encumbrance;
    }

    if (filter.housingConditionIds) {
      params.housingConditionIds = filter.housingConditionIds;
    }

    if (filter.moreThanFiveRooms !== null) {
      params.moreThanFiveRooms = filter.moreThanFiveRooms;
    }

    if (filter.exchange) {
      params.exchange = filter.exchange;
    }

    if (filter.probabilityOfBidding) {
      params.probabilityOfBidding = filter.probabilityOfBidding;
    }

    if (filter.objectPrice?.from) {
      params.objectPriceFrom = filter.objectPrice.from;
    }

    if (filter.objectPrice?.to) {
      params.objectPriceTo = filter.objectPrice.to;
    }

    if (filter.totalArea?.from) {
      params.totalAreaFrom = filter.totalArea.from;
    }

    if (filter.totalArea?.to) {
      params.totalAreaTo = filter.totalArea.to;
    }

    if (filter.yearOfConstruction?.from) {
      params.yearOfConstructionFrom = filter.yearOfConstruction.from;
    }

    if (filter.yearOfConstruction?.to) {
      params.yearOfConstructionTo = filter.yearOfConstruction.to;
    }

    if (filter.residentialComplexIds) {
      params.residentialComplexIds = filter.residentialComplexIds;
    }

    if (filter.applicationId) {
      params.applicationId = filter.applicationId;
    }

    if (filter.direction && filter.sortBy) {
      params.sortBy = [filter.sortBy, filter.direction.toLowerCase()].join('-');
    }

    return params;
  }

  public saveAddressToSession(address: IAddressFields): void {
    sessionStorage.setItem('address_field', JSON.stringify(address));
  }

  public get addressFields(): IAddressFields {
    return JSON.parse(sessionStorage.getItem('address_field')) || JSON.parse(JSON.stringify(DEFAULT_ADDRESS_FIELDS));
  }

  public saveAddressCode(address: IAddressCode): void {
    sessionStorage.setItem('address_code', JSON.stringify(address));
  }

  public get addressCodes(): IAddressCode {
    return JSON.parse(sessionStorage.getItem('address_code')) || JSON.parse(JSON.stringify(DEFAULT_ADDRESS_CODES));
  }

  public parseParamsAsFilter(params: ParamMap): models.GetRealPropertySellApplicationListParams {
    const filter = models.REAL_PROPERTY_FILTER();

    if (params.has('addressCodes')) {
      filter.addressCodes = params.getAll('addressCodes');
    } else if (params.has('addressCode')) {
      filter.addressCodes = params.getAll('addressCode');
    }

    if (params.has('operationTypeId')) {
      filter.operationTypeId = parseInt(params.get('operationTypeId'), 10);
    }

    if (params.has('objectTypeId')) {
      filter.objectTypeId = parseInt(params.get('objectTypeId'), 10);
    }

    if (params.has('atelier')) {
      filter.atelier = JSON.parse(params.get('atelier'));
    }

    if (params.has('numberOfRooms')) {
      filter.numberOfRooms = params.getAll('numberOfRooms').map((n) => parseInt(n, 10));
    }

    if (params.has('encumbrance')) {
      filter.encumbrance = JSON.parse(params.get('encumbrance'));
    }

    if (params.has('housingConditionIds')) {
      filter.housingConditionIds = params.getAll('housingConditionIds').map(Number);
    }

    if (params.has('moreThanFiveRooms')) {
      filter.moreThanFiveRooms = JSON.parse(params.get('moreThanFiveRooms'));
    }

    if (params.has('exchange')) {
      filter.exchange = JSON.parse(params.get('exchange'));
    }

    if (params.has('probabilityOfBidding')) {
      filter.probabilityOfBidding = JSON.parse(params.get('probabilityOfBidding'));
    }

    if (params.has('objectPriceFrom')) {
      filter.objectPrice.from = parseInt(params.get('objectPriceFrom'), 10);
    }

    if (params.has('objectPriceTo')) {
      filter.objectPrice.to = parseInt(params.get('objectPriceTo'), 10);
    }

    if (params.has('totalAreaFrom')) {
      filter.totalArea.from = parseInt(params.get('totalAreaFrom'), 10);
    }

    if (params.has('totalAreaTo')) {
      filter.totalArea.to = parseInt(params.get('totalAreaTo'), 10);
    }

    if (params.has('yearOfConstructionFrom')) {
      filter.yearOfConstruction.from = parseInt(params.get('yearOfConstructionFrom'), 10);
    }

    if (params.has('yearOfConstructionTo')) {
      filter.yearOfConstruction.to = parseInt(params.get('yearOfConstructionTo'), 10);
    }

    if (params.has('residentialComplexIds')) {
      filter.residentialComplexIds = params.getAll('residentialComplexIds').map((n) => parseInt(n, 10));
    }

    if (params.has('applicationId')) {
      filter.applicationId = parseInt(params.get('applicationId'), 10);
    }

    if (params.has('sortBy')) {
      filter.sortBy = params.get('sortBy').split('-')[0];
      filter.direction = params.get('sortBy').split('-')[1].toUpperCase() as any;
    } else if (!params.has('sortBy')) {
      filter.sortBy = 'createdDate';
      filter.direction = 'DESC';
    }

    if (params.has('page')) {
      filter.pageNumber = parseInt(params.get('page'), 10) - 1;
    }

    return filter;
  }
}
