import { createEntityAdapter } from '@ngrx/entity';
import { IFeedbackState } from '@app/ngrx/feedback/states/feedback.state';
import { createReducer, on } from '@ngrx/store';

import * as feedbackActions from '../actions';

export const adapter = createEntityAdapter();

const feedbackInitialState: IFeedbackState = {
  loading: false,
  loaded: false,
  feedbacks: [],
  totalCount: 0,
};

export const feedbackReducer = createReducer(
  feedbackInitialState,
  on(feedbackActions.getAllFeedback, feedbackActions.getMoreFeedback, (state) => ({ ...state, loading: true, loaded: false })),
  on(feedbackActions.getAllFeedbackSuccess, (state, { feedbacks, totalCount }) => {
    return { ...state, loading: false, loaded: true, feedbacks, totalCount };
  }),
  on(feedbackActions.getMoreFeedbackSuccess, (state, { feedbacks, totalCount }) => {
    return { ...state, loading: false, loaded: true, feedbacks: [...state.feedbacks, ...feedbacks], totalCount };
  }),
  on(feedbackActions.getAllFeedbackFailure, feedbackActions.getMoreFeedbackFailure, (state) => ({ ...state, loading: false, loaded: false })),
);
