import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { RealPropertyService } from '@modules/real-property-details';

import { exhaustMap, of, switchMap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import * as realPropertyActions from '../actions';

@Injectable()
export class RealPropertyDataEffects {

  getData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(realPropertyActions.getRealPropertyData),
      switchMap(({ filter }) => {
        return this._realPropertyService.getRealPropertyList(filter).pipe(
          map((data) => {
            return { count: data.total, data: this._realPropertyService.serializeRealPropertyData(data.data.data) }
          }),
          map(({ count, data }) => {
            return realPropertyActions.getRealPropertyDataSuccess({ data: data, totalCountData: count });
          }),
          catchError((error) => of(realPropertyActions.getRealPropertyDataFailure({ errorMessage: error.message })))
        );
      }),
    );
  });

  getCount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(realPropertyActions.getRealPropertyDataCount),
      exhaustMap(({ filter }) => {
        return this._realPropertyService.getRealPropertyCount(filter).pipe(
          map((count) => {
            return realPropertyActions.getRealPropertyDataCountSuccess({ count });
          }),
          catchError((error) => of(realPropertyActions.getRealPropertyDataCountFailure({ errorMessage: error.message }))),
        );
      }),
    );
  });

  constructor(
    private actions$: Actions,
    private _realPropertyService: RealPropertyService,
  ) {}
}
