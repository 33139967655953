import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { FeedbackService } from '@modules/feedback/feedback.service';

import * as feedbackActions from '../actions';
import { of, switchMap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class FeedbackEffects {

  getAllFeedback$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(feedbackActions.getAllFeedback),
      switchMap(({ filter }) => {
        return this._feedbackService.getAllFeedbackRequest(filter).pipe(
          map((data) => {
            return feedbackActions.getAllFeedbackSuccess({ feedbacks: data.data.data, totalCount: data.total });
          }),
          catchError((err) => of(feedbackActions.getAllFeedbackFailure({ errorMessage: err.message }))),
        );
      }),
    );
  });

  getMoreFeedback$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(feedbackActions.getMoreFeedback),
      switchMap(({ filter }) => {
        return this._feedbackService.getAllFeedbackRequest(filter).pipe(
          map((data) => {
            return feedbackActions.getMoreFeedbackSuccess({ feedbacks: data.data.data, totalCount: data.total });
          }),
          catchError((err) => of(feedbackActions.getMoreFeedbackFailure({ errorMessage: err.message }))),
        );
      }),
    );
  });

  constructor(private actions$: Actions, private _feedbackService: FeedbackService) {}
}