import { createAction, props } from '@ngrx/store';
import { GetProfileConfigByDeviceUuidResp } from '@modules/real-property-search-settings/models';

const SEARCH = 'SAVED-SEARCH';

export const getSearch = createAction(`[${SEARCH}] Get Search`);
export const getSearchSuccess = createAction(`[${SEARCH}] Get Search Success`, props<{ data: GetProfileConfigByDeviceUuidResp }>());
export const getSearchFailure = createAction(`[${SEARCH}] Get Search Failure`, props<{ errorMessage: string }>());

export const removeSearch = createAction(`[${SEARCH}] Remove Search`, props<{ id: number }>());
export const removeSearchSuccess = createAction(`[${SEARCH}] Remove Search Success`, props<{ id: number }>());
export const removeSearchFailure = createAction(`[${SEARCH}] Remove Search Failure`, props<{ errorMessage: string }>());
