import * as vm from 'generated/api/vm.api';

export const operationOptions = [
  { label: 'Покупка', value: 2, nameUrl: 'buy' },
  { label: 'Аренда', value: 3, nameUrl: 'rent' },
];

export const roomOptions = [
  { name: '1', value: 1 },
  { name: '2', value: 2 },
  { name: '3', value: 3 },
  { name: '4', value: 4 },
  { name: '5+', value: 5 },
];

export const objectLayoutOptions = [
  { label: 'Любая', value: null },
  { label: 'Студия', value: true },
  { label: 'Изолированные комнаты', value: false },
];

export enum RealPropertyListViewOptions {
  Feed = 'feed',
  List = 'list',
}

export interface RealPropertySettings {
  cityCode: string | null;
  districtCode: string | null;
  streetCode: string | null;
  filterByAddressObject: 'cityCode' | 'districtCode' | 'streetCode' | null;
  viewListAs: RealPropertyListViewOptions;
}

export const REAL_PROPERTY_FILTER = (): GetRealPropertySellApplicationListParams => ({
  pageNumber: 0,
  pageSize: 20,
  operationTypeId: 2,
  applicationId: null,
  addressCodes: ['001001'],
  objectTypeId: null,
  residentialComplexIds: null,
  encumbrance: null,
  exchange: null,
  atelier: null,
  housingConditionIds: null,
  direction: null,
  sortBy: null,
  numberOfRooms: [],
  moreThanFiveRooms: null,
  probabilityOfBidding: null,
  objectPrice: {
    from: null,
    to: null,
  },
  totalArea: {
    from: null,
    to: null,
  },
  yearOfConstruction: {
    from: null,
    to: null,
  },
});

type GetRealPropertySellApplicationList = vm.paths['/open-api/realProperty/getRealPropertyForWebClientApplication'];
export type GetRealPropertySellApplicationListParams = GetRealPropertySellApplicationList['post']['parameters']['body']['dto'];
export type RealPropertySellApplicationResp = GetRealPropertySellApplicationList['post']['responses']['200']['schema'];
export type RealPropertySellApplicationList = GetRealPropertySellApplicationList['post']['responses']['200']['schema']['data']['data'];
