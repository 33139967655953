import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import * as models from './phone-status-api.model';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PhoneStatusApiService {
  constructor(private http: HttpClient) {}

  public fetchPhoneStatus(value: models.PhoneStatusParams): Observable<HttpResponse<models.PhoneStatusResp>> {
    return this.http.get(`${environment.apiUserManagerUrl}/open-api/users/findByLogin/${value}`, { observe: 'response' });
  }

  public sendPhoneForLogin(value: models.PhoneStatusParams): Observable<models.PhoneStatusResp> {
    return this.http.get(`${environment.apiUserManagerUrl}/open-api/users/findByLogin/${value}`);
  }
}
