import { IRealPropertyMapDataState } from '@app/ngrx/real-property-data-map/states/real-property-data-map.state';
import { createReducer, on } from '@ngrx/store';

import * as realPropertyMapActions from '../actions';
import { createEntityAdapter } from '@ngrx/entity';

export const adapter = createEntityAdapter();

export const getInitialRealPropertyMapData: IRealPropertyMapDataState = adapter.getInitialState({
  loaded: false,
  loading: false,
  totalCountMapData: 0,
});

export const realPropertyMapDataReducer = createReducer(
  getInitialRealPropertyMapData,
  on(realPropertyMapActions.getRealPropertyMapDataCount, (state) => ({ ...state, loading: true, loaded: false })),
  on(realPropertyMapActions.getRealPropertyMapDataCountSuccess, (state, { count }) => {
    return { ...state, loading: false, loaded: true, totalCountFilter: count };
  }),
  on(realPropertyMapActions.getRealPropertyMapDataSuccess, (state, { data }) => {
    return adapter.setAll(data, { ...state, loading: false, loaded: true });
  }),
);
