import { AppState } from '@app/ngrx/app-state/app-state';
import { createSelector } from '@ngrx/store';
import { adapter } from '@app/ngrx/street/reducers';

export const getStreetState = (state: AppState) => state.street;

export const getStreetCollection = adapter.getSelectors(getStreetState).selectAll;

export const getStreetEntities = adapter.getSelectors(getStreetState).selectEntities;

export const getStreetLoading = createSelector(getStreetState, (state) => state.loading);

export const getCurrentStreetIds = createSelector(getStreetState, (state) => state.currentStreetIds);

export const getCurrentStreets = createSelector(
    getCurrentStreetIds,
    getStreetEntities,
    (ids, entities) => ids.map(id => entities[id])
);