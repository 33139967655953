import { AppState } from '@app/ngrx/app-state/app-state';
import { adapter } from '@app/ngrx/complex/reducers';
import { createSelector } from '@ngrx/store';

export const getComplexState = (state: AppState) => state.complex;

export const getComplexCollection = adapter.getSelectors(getComplexState).selectAll;

export const getComplexEntities = adapter.getSelectors(getComplexState).selectEntities;

export const getComplexLoading = createSelector(getComplexState, (state) => state.loading);

export const getCurrentComplexIds = createSelector(getComplexState, (state) => state.currentComplexIds);

export const getCurrentComplex = createSelector(
  getCurrentComplexIds,
  getComplexEntities,
  (ids, entities) => ids.map(id => entities[id])
);
