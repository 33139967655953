import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CurrencyFormat } from '@helpers/index';
import { WithLoadingPipe } from '@helpers/loading.pipe';
import { NgSelectModule } from '@ng-select/ng-select';
import { DictionarySelectModule } from '@shared/modules/dictionary-select/dictionary-select.module';
import * as primeNgServices from '@shared/modules/prime-ng/services';
// import { AngularYandexMapsModule, YaConfig } from 'angular8-yandex-maps';
import * as moment from 'moment';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxMaskModule } from 'ngx-mask';
import * as components from './components';
import { FileComponent } from './components/file/file.component';
import { RealPropertyAgentLinkComponent } from './components/real-property-agent-link/real-property-agent-link.component';
import { RealPropertyMediaContentComponent } from './components/real-property-media-content/real-property-media-content.component';
import { ResidentialComplexDetailsLinkComponent } from './components/residential-complex-details-link/residential-complex-details-link.component';
import { PrimeNgModule } from './modules/prime-ng/prime-ng.module';
import { SpinnerModule } from './modules/spinner/spinner.module';
import { SafeHtmlPipe } from './pipes/safe-html/safe-html.pipe';
import { services } from './services';
import { SelectComponent } from '@shared/modules/select/select.component';
import { AvatarComponent } from './components';
import { YConfig, YMapComponent, YMapDefaultSchemeLayerDirective, provideYConfig } from 'angular-yandex-maps-v3';

// export const mapConfig: YaConfig = {
//   apikey: 'faf6d83d-289e-4cc2-8f4d-e91388b2799a',
//   lang: 'ru_RU',
//   version: '2.1',
// };
const config: YConfig = {
  apikey: 'faf6d83d-289e-4cc2-8f4d-e91388b2799a',
  lang: 'ru_RU',
};

@NgModule({
  imports: [
    // YMapComponent,
    // YMapDefaultSchemeLayerDirective,
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    InfiniteScrollModule,
    PrimeNgModule,
    SpinnerModule,
    // ScrollingModule,
    NgSelectModule,
    NgxMaskModule,
    // AngularYandexMapsModule.forRoot(mapConfig),
    DictionarySelectModule,
    SelectComponent,
    AvatarComponent,
    // SelectModule,
  ],
  providers: [
    provideYConfig(config),
    CurrencyPipe,
    ...services,
    ...primeNgServices.services,
    {
      provide: 'moment',
      useValue: moment,
    },
  ],
  declarations: [
    WithLoadingPipe,
    CurrencyFormat,
    ...components.components,
    FileComponent,
    SafeHtmlPipe,
    RealPropertyAgentLinkComponent,
    ResidentialComplexDetailsLinkComponent,
    RealPropertyMediaContentComponent,
  ],
  exports: [
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    InfiniteScrollModule,
    PrimeNgModule,
    SpinnerModule,
    ...components.components,
    CurrencyFormat,
    WithLoadingPipe,
    // ScrollingModule,
    NgSelectModule,
    NgxMaskModule,
    FileComponent,
    SafeHtmlPipe,
    DictionarySelectModule,
    // SelectModule,
    ResidentialComplexDetailsLinkComponent,
    // AngularYandexMapsModule,
    // YMapComponent,
    // YMapDefaultSchemeLayerDirective,
    SelectComponent,
    AvatarComponent,
  ],
})
export class SharedModule {}
