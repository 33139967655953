import { createAction, props } from '@ngrx/store';

const REALPROPERTY = 'REAL PROPERTY FOR MAP';

export const getRealPropertyMapData = createAction(`[${REALPROPERTY}] Get MapData`, props<{ filter: any }>());
export const getRealPropertyMapDataSuccess = createAction(`[${REALPROPERTY}] Get MapData Success`, props<{ data: any }>());
export const getRealPropertyMapDataFailure = createAction(`[${REALPROPERTY}] Get MapData Failure`, props<{ errorMessage: string }>());

export const getRealPropertyMapDataCount = createAction(`[${REALPROPERTY}] Get Count`, props<{ filter: any }>());
export const getRealPropertyMapDataCountSuccess = createAction(`[${REALPROPERTY}] Get Count Success`, props<{ count: number }>());
export const getRealPropertyMapDataCountFailure = createAction(`[${REALPROPERTY}] Get Count Failure`, props<{ errorMessage: string }>());
