import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import * as models from '../models';

@Injectable()
export class FileService {
  constructor(private http: HttpClient) {}

  public getPdfFile(uuid: models.GetPdfFileParams): Observable<Blob> {
    return this.http.get(`${environment.apiFileManagerUrl}/open-api/download/${uuid}`, { responseType: 'blob' });
  }
}
