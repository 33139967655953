import { createAction, props } from '@ngrx/store';

const REALPROPERTY = 'REAL PROPERTY';

export const getRealPropertyData = createAction(`[${REALPROPERTY}] Get Data`, props<{ filter: any }>());
export const getRealPropertyDataSuccess = createAction(`[${REALPROPERTY}] Get Data Success`, props<{ data: any, totalCountData: number }>());
export const getRealPropertyDataFailure = createAction(`[${REALPROPERTY}] Get Data Failure`, props<{ errorMessage: string }>());

export const updateRealPropertyItemSuccess = createAction(`[${REALPROPERTY}] Update Item`, props<{ item: any }>());

export const getRealPropertyDataCount = createAction(`[${REALPROPERTY}] Get Count`, props<{ filter: any }>());
export const getRealPropertyDataCountSuccess = createAction(`[${REALPROPERTY}] Get Count Success`, props<{ count: number }>());
export const getRealPropertyDataCountFailure = createAction(`[${REALPROPERTY}] Get Count Failure`, props<{ errorMessage: string }>());
