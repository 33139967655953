import { AppState } from '@app/ngrx/app-state/app-state';
import { adapter } from '@app/ngrx/district/reducers';
import { createSelector } from '@ngrx/store';

export const districtState = (state: AppState) => state.district;

export const getDistrictCollection = adapter.getSelectors(districtState).selectAll;

export const getDistrictLoading = createSelector(districtState, (state) => state.loading);
export const getDistrictLoaded = createSelector(districtState, (state) => state.loaded);

export const getCurrentDistrictIds = createSelector(districtState, (state) => state.currentDistrictIds);

export const getDistrictEntities = adapter.getSelectors(districtState).selectEntities;

export const getCurrentDistricts = createSelector(
  getCurrentDistrictIds,
  getDistrictEntities,
  (ids, entities) => ids.map(id => entities[id])
);
