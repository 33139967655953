import { createAction, props } from '@ngrx/store';
import * as sharedModels from '@shared/models';

const CITY = 'CITY';

export const getAllCity = createAction(`[${CITY}] Get All Cities`, props<{ objectTypeId: any }>());
export const getAllCitySuccess = createAction(`[${CITY}] Get All Cities Success`, props<{ allCities: sharedModels.AddressObject[] }>());
export const getAllCityFailure = createAction(`[${CITY}] Get All Cities Failure`, props<{ errorMessage: string }>());

export const updateCurrentCity = createAction(`[${CITY}] Update Current City`, props<{ currentCityId: number }>());
