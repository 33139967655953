import { AppState } from '@app/ngrx/app-state/app-state';
import { adapter } from '@app/ngrx/dictionary/reducers';
import { createSelector } from '@ngrx/store';

export const getDictionaryState = (state: AppState) => state.dictionary;

export const getHouseConditionCollection = adapter.getSelectors(getDictionaryState).selectAll;

export const getHouseConditionEntities = adapter.getSelectors(getDictionaryState).selectEntities;

export const getHouseConditionLoading = createSelector(getDictionaryState, (state) => state.loading);

export const getCurrentHouseConditionIds = createSelector(getDictionaryState, (state) => state.currentHouseConditionIds);

export const getCurrentObjectTypeId = createSelector(getDictionaryState, (state) => state.currentObjectTypesId);

export const getCurrentHouseCondition = createSelector(
  getHouseConditionEntities,
  getCurrentHouseConditionIds,
  (entities, ids) => ids.map((id) => entities[id]),
);

export const getObjectTypesCollection = createSelector(getDictionaryState, (state) => state.objectTypes);
