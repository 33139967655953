import { createEntityAdapter } from '@ngrx/entity';
import { IComplexState } from '@app/ngrx/complex/states/complex.state';
import { createReducer, on } from '@ngrx/store';

import * as complexActions from '../actions';
import {getMoreComplex} from "../actions";

export const adapter = createEntityAdapter();

const complexInitialState: IComplexState = adapter.getInitialState({
  loaded: false,
  loading: false,
  currentComplexIds: [],
  totalNumberElements: 0,
});

export const complexReducer = createReducer(
  complexInitialState,
  on(complexActions.getAllComplex, getMoreComplex, (state) => ({ ...state, loading: true, loaded: false })),
  on(complexActions.getAllComplexSuccess, (state, { allComplexes, totalNumberElements }) => {
    return adapter.setAll(allComplexes, { ...state, loading: false, loaded: true, totalNumberElements });
  }),
  on(complexActions.getMoreComplexSuccess, (state, { allComplexes, totalNumberElements }) => {
    return adapter.addMany(allComplexes, { ...state, loading: false, loaded: true, totalNumberElements });
  }),
  on(complexActions.updateCurrentComplex, (state, { currentComplexIds }) => {
    return { ...state, currentComplexIds };
  }),
  on(
    complexActions.getAllComplexFailure,
    complexActions.getMoreComplexFailure,
    (state) => ({ ...state, loading: false, loaded: false })
  ),
);
