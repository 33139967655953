import { AppState } from '@app/ngrx/app-state/app-state';
import { createSelector } from '@ngrx/store';

export const getFeedbackState = (state: AppState) => state.feedback;

export const getFeedbackCollection = createSelector(getFeedbackState, (state) => state.feedbacks);

export const getFeedbackLoading = createSelector(getFeedbackState, (state) => state.loading);

export const getFeedbackTotal = createSelector(getFeedbackState, (state) => state.totalCount);
