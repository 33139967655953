import { createAction, props } from '@ngrx/store';

const STREET = 'STREET';

export const getAllStreet = createAction(`[${STREET}] Get All Street`, props<{ filters: { pageNumber: number, pageSize: number, text?: string } }>());
export const getAllStreetSuccess = createAction(`[${STREET}] Get All Street Success`, props<{ allStreets: any, totalNumberElements: number }>());
export const getAllStreetFailure = createAction(`[${STREET}] Get All Street Failure`, props<{ errorMessage: string }>());

export const updateCurrentStreet = createAction(`[${STREET}] Update Current Street`, props<{ currentStreetIds: number[] }>());

export const getMoreStreet = createAction(`[${STREET}] Get More Street`, props<{ filters: { pageNumber: number, pageSize: number, text?: string } }>());
export const getMoreStreetSuccess = createAction(`[${STREET}] Get More Street Success`, props<{ allStreets: any, totalNumberElements: number }>());
export const getMoreStreetFailure = createAction(`[${STREET}] Get More Street Failure`, props<{ errorMessage: string }>());
