import { createReducer, on } from '@ngrx/store';
import { ICityState } from '@app/ngrx/city/states';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import * as cityActions from '../actions';

export const adapter: EntityAdapter<any> = createEntityAdapter();

export const cityInitialState: ICityState = adapter.getInitialState({
  loading: false,
  loaded: false,
  currentCityId: null,
});

export const cityReducer = createReducer(
  cityInitialState,
  on(cityActions.getAllCity, (state) => ({ ...state, loading: true, loaded: false })),
  on(cityActions.getAllCitySuccess, (state, { allCities }) => {
    return adapter.setAll(allCities, { ...state, loading: false, loaded: true });
  }),
  on(cityActions.getAllCityFailure, (state) => ({ ...state, loading: false, loaded: false })),
  on(cityActions.updateCurrentCity, (state, { currentCityId }) => {
    return { ...state, currentCityId };
  }),
);
